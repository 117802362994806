.Container form {
  width: 400px;
  background: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #2f2e2e;
  padding-bottom: 10%;
}

.Container label {
  padding: 30px;
  font-size: 1.5em;
  color: rgb(80, 77, 77);
}

.Container img {
  width: 410px;
  display:block;
  margin: auto;
  margin-top: 80px;
  margin-bottom: 20px;
}

.Container p.Error {
  color: #ff3333;
  margin-bottom: 15px;
  /*border: 1px solid #ff3333;
  background-color: #ff3333;
  color: #fff;
  border-radius: 8px;*/
  padding: 10px;
  width: 100%;
  text-align: center;
  font-size: 1.3em;
}

.Container form input {
  flex: 1;
  height: 45px;
  margin-bottom: 30px;
  padding: 10px 20px;
  color: #777;
  font-size: 15px;
  width: 100%;
  border: 1px solid #ddd; 
}

.Container input::placeholder {
  color: #999;
}

.Container button {
  color: #fff;
  font-size: 16px;
  background: #8cc640;
  height: 56px;
  border: 0;
  border-radius: 5px;
  width: 100%;
}
.Container hr {
  margin: 20px 0;
  border: none;
  border-bottom: 1px solid #cdcdcd;
  width: 100%;
}
.Container a {
  font-size: 16;
  font-weight: bold;
  color: #999;
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
  .Container form {
    width: 90%;
    display:block;
    margin-left: auto;
    margin-right: auto;
  }
  .Container img {
    width: 90%;
    display:block;
    margin: auto;
  }
}
